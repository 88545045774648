// const main = 'http://localhost:8000'
const main = 'http://truecoffeego.truecorp.co.th/call-api.php?url=http://admin-truecoffeegotruecorpcoth.theflight19.codeorange.host'
const storage = `${main}/storage`
// TODO Set url
const mainUrl = 'https://truecoffeego.truecorp.co.th'

// https://truecoffeego.truecorp.co.th/

const url = {
  api: `${main}/api`,
  menuImg: `${storage}/img/menu`,
  promotionImg: `${storage}/img/promotions`,
  mainUrl: 'https://truecoffeego.truecorp.co.th',
  metaUrl: `${mainUrl}/promotion-detail`,
  shareUrl: `${mainUrl}/promotion-detail`
}

export default url
