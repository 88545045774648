import axios from 'axios'
import url from '@/resources/_url'

const state = {
  isLoadStore: false,
  rawStores: null
}

const mutations = {
  async GETSTORE (state) {
    state.isLoadStore = true

    const solution = await {
      method: 'get',
      url: `${url.api}/store`
    }

    await axios(solution)
      .then(res => {
        let data = res.data

        state.rawStores = data
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        state.isLoadStore = false
      })
  }
}

const actions = {
  getStore ({ commit }) {
    commit('GETSTORE')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
