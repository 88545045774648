import axios from 'axios'
import url from '@/resources/_url'

const state = {
  isLoadCate: false,
  isLoadFilter: false,
  isLoadMenu: false,
  hadCate: false,
  hadFilter: false,
  hadMenu: false,
  rawCategories: [],
  rawFilters: [],
  rawMenus: null
}

const mutations = {
  GETCATEGORY (state) {
    state.isLoadCate = true
    state.hadCate = false

    const solution = {
      method: 'get',
      url: `${url.api}/menu/category`
    }

    axios(solution)
      .then(res => {
        let data = res.data

        state.rawCategories = data
        state.hadCate = true
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        state.isLoadCate = false
      })
  },

  GETFILTER (state) {
    state.isLoadFilter = true
    state.hadFilter = false

    const solution = {
      method: 'get',
      url: `${url.api}/menu/filter`
    }

    axios(solution)
      .then(res => {
        let data = res.data

        state.rawFilters = data
        state.hadFilter = true
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        state.isLoadFilter = false
      })
  },

  async GETMENU (state, cateAndFilter) {
    state.rawMenus = null
    state.hadMenu = false
    state.isLoadMenu = true

    const category = cateAndFilter.category
    const filters = cateAndFilter.filter

    let newFilter = []

    await filters.forEach(filter => {
      newFilter.push(filter.id)
    })

    const solution = {
      method: 'get',
      url: `${url.api}/menu`,
      params: {
        category: category,
        filter: newFilter
      }
    }

    await axios(solution)
      .then(res => {
        let data = res.data.data

        state.rawMenus = data
        state.hadMenu = true
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        state.isLoadMenu = false
      })
  },

  GETMENUOLD (state, cateAndFilter) {
    state.isLoadMenu = true

    const category = cateAndFilter.category
    const filters = cateAndFilter.filter

    if (filters.length > 0) {
      filters.forEach((filter, i) => {
        const solution = {
          method: 'get',
          url: `${url.api}/menu`,
          params: {
            category: category,
            filter: filter
          }
        }

        axios(solution)
          .then(res => {
            let data = res.data.data

            if (filter === 'all') {
              if (i === 0) {
                state.rawMenus = null
                state.rawMenus = data
              }
            } else {
              state.rawMenus.concat(data)
              // let noDup = [...newRawMenus]

              // noDup.forEach(data => {
              //   if (data.filter === filters[i]) {
              //     // state.rawMenus.push(data)
              //   }
              // })
            }
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => {
            state.isLoadMenu = false
          })
      })
    }
  }
}

const actions = {
  getCategory ({ commit }) {
    commit('GETCATEGORY')
  },

  getFilter ({ commit }) {
    commit('GETFILTER')
  },

  getMenu ({ commit }, cateAndFilter) {
    commit('GETMENU', cateAndFilter)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
