import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home/router'
// import Landing from '@/views/Landing/router'
import About from '@/views/About/router'
import Menu from '@/views/Menu/router'
import Store from '@/views/Store/router'
import Promotions from '@/views/Promotions/router'
import PromotionDetail from '@/views/Promotions/Detail/router'
import Application from '@/views/Application/router'

Vue.use(VueRouter)

const routes = [
  // Landing,
  Home,
  About,
  Menu,
  Store,
  Promotions,
  PromotionDetail,
  Application,
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  next()
})

export default router
