<template>
  <div id="app">
    <Header />
    <ModalApp />
    <main >
      <keep-alive><router-view/></keep-alive>
    </main>
    <Footer v-if="!isLoading" id="footer" />
  </div>
</template>

<script>
import { mapState } from 'vuex'

const Header = () => import('@/components/Header')
const Footer = () => import('@/components/Footer')

export default {
  components: {
    Header,
    Footer
  },

  data () {
    return {
      isLoading: true
    }
  },

  computed: {
    ...mapState({
      isLoadCate: state => state.menu.isLoadCate,
      isLoadFilter: state => state.menu.isLoadFilter,
      isLoadMenu: state => state.menu.isLoadMenu,
      isLoadPromotion: state => state.promotion.isLoadPromotion
    })
  },

  methods: {
    checkShowLoad (isLoad) {
      if (isLoad) {
        this.isLoading = true
      } else {
        this.isLoading = false
      }
    }
  },

  watch: {
    '$route' () {
      if (this.$route.name !== 'menu') {
        this.isLoading = false
      }
    },

    isLoadCate (isLoad) {
      this.checkShowLoad(isLoad)
    },

    isLoadFilter (isLoad) {
      this.checkShowLoad(isLoad)
    },

    isLoadMenu (isLoad) {
      this.checkShowLoad(isLoad)
    },

    isLoadPromotion (isLoad) {
      this.checkShowLoad(isLoad)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variable.scss';

#loading {
  transition: 10s;
}

#main,
#footer {
  opacity: 0;
  animation: main 800ms $ease forwards;
  transition: 800ms;

  @keyframes main {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

#footer {
  animation-delay: 100ms;
}
</style>
