import axios from 'axios'
import url from '@/resources/_url'

const state = {
  isLoadPromotion: false,
  rawPromotions: [],
  rawPromotionHome: []
}

const mutations = {
  GETPROMOTION (state) {
    state.isLoadPromotion = true

    const solution = {
      method: 'get',
      url: `${url.api}/promotions`
    }

    axios(solution)
      .then(res => {
        let data = res.data
        state.rawPromotions = data
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        state.isLoadPromotion = false
      })
  },

  GETPROMOTIONHOME (state) {
    state.isLoadPromotion = true
    state.rawPromotionHome = []

    for (let i = 1; i < 3; i++) {
      const solution = {
        method: 'get',
        url: `${url.api}/promotion`,
        params: {
          no: i
        }
      }

      axios(solution)
        .then(res => {
          let data = res.data

          if (data[0] !== 'no data') {
            state.rawPromotionHome.push(data)
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          state.isLoadPromotion = false
        })
    }
  }
}

const actions = {
  getPromotion ({ commit }) {
    commit('GETPROMOTION')
  },

  getPromotionHome ({ commit }) {
    commit('GETPROMOTIONHOME')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
