import Vue from 'vue'
import Vuex from 'vuex'
import menu from './modules/api/menu'
import store from './modules/api/store'
import promotion from './modules/api/promotion'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    menu,
    store,
    promotion
  }
})
